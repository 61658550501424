<template>
  <section class="content-banner">
    <div class="content-banner-itens">
      <template v-if="loading">
          <PuSkeleton
            height="600px"                      
            class="content-banner-itens-image"/>
      </template>
      <template v-else>
        <!-- <video playsinline autoplay muted loop :poster="detail.image_path" style="object-fit: cover !important;
          width: 100vw;
          height: 100vh;
          position: fixed;
          top: 0;
          left: 0;">          
          <source :src="detail.video_path" type="video/mp4">
        </video> -->
        <div
          class="content-banner-itens-image d-none d-lg-block"
          :style="{
            background: `url('${detail.image_path}')`,
          }"></div>
        <div
          class="content-banner-itens-image d-lg-none"
          :style="{
            background: `url('${detail.image_mobile_path}')`,
          }"></div>
      </template>      
      <div v-if="!loading" class="content-banner-itens-text">
        <div class="content-banner-itens-text-title">
          <h1>{{ detail.name }}<span>{{ detail.subtitle }}</span></h1>
        </div>
        <button class="btn-cotation-default" @click="toggleFormFixed()">
          {{$t('vehicle_new_details.button_cotation')|stripHtml}}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("VehicleNew", ["loading", "detail"]),
  },
  methods: {
    ...mapActions('Site',["toggleFormFixed"]),
  },
};
</script>

<style lang="scss">
.content-banner {
  &-itens {
    position: relative;
    &-image {
      height: 740px;
      width: 100%;
      background-position: center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      @media (min-width: 992px) and (max-height: 800px) {
        height: 500px;
      }
      @media (max-width: 991px) {
        height: 400px;
      }
    }
    &-text {
      position: absolute;
      left: 50px;
      top: 50%;
      transform: translateY(-50%);
      @media (max-width: 991px) {
        left: 50%;
        transform: translateX(-50%);
        top: initial;
        bottom: 30px;
        width: 100%;
        max-width: 400px;
        padding: 0px 15px;
      }
      &-title {
        background: rgba(12, 39, 67, 0.75);
        border: 1px solid var(--white);
        border-radius: 10px;
        padding: 15px 30px;
        margin-bottom: 17px;
        text-align: left;
        @media (max-width: 991px) {
          text-align: center;
          margin-bottom: 25px;
        }
        h1 {
          font-size: 60px;
          color: var(--white);
          margin-bottom: 0;
          font-family: "Font Bold";
          line-height: 1;
          @media (max-width: 991px) {
            font-size: 40px;
          }
          @media (max-width: 359px) {
            font-size: 30px;
          }
          span {
            display: block;
            font-family: "Font Medium";
            font-size: 30px;
            @media (max-width: 359px) {
              font-size: 24px;
            }
          }
        }
      }
      .btn-cotation-default {
        @media (max-width: 991px) {
          margin: 0 auto;
        }
      }
    }
  }
}
</style>
