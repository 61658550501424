<template>
  <section class="content-description-vehicle">
    <div class="content-description-vehicle-itens container">
      <div class="row">
        <template v-if="loading">
          <PuSkeleton class="w-100" height="400px" />
        </template>
        <template v-else-if="Object.keys(detail).length > 0 && detail.versions.length > 0">
          <div class="content-tab-versions">
            <button
              class="content-tab-versions-elements"
              v-for="(version, index) in detail.versions"
              :key="version.id"              
              @click="selectTabVersion(index)"
              :class="{ active: activeTab === 'tab-' + index}"
            >
              <p>{{ version.name }}</p>
            </button>
          </div>
          <div
            class="col-12 col-lg-8 content-tab-version-div"
            v-for="(version, index) in detail.versions"
            :class="{ 'active-tab': activeTab === 'tab-' + index }"
            :key="version.id"            
          >
            <div class="content-description-vehicle-itens-header">
              <h2 class="title">{{ version.name }}</h2>
            </div>
            <div class="content-description-vehicle-itens-colors">
              <button
                v-for="color in version.colors"
                :class="{'elements-colors': true, 'active' : (colorIndex == color.id)}"
                :key="color.id"
                @click="selectVehicleColor(color)"
                :style="color.color"
              ></button>              
            </div>
            <div class="content-description-vehicle-itens-body">
              <div class="content-image">
                <img
                  class="img-fluid"
                  :src="getVehicleColor.image_path"
                  :alt="getVehicleColor.vehicle_new_version.name"
                  title="image vehicle intern"
                />
              </div>
              <!-- <div class="content-text">
                <h3>A partir de <span>R$ 12.240*</span></h3>
              </div> -->
              <button style="margin-top: 50px;" class="btn-cotation-default" @click="toggleFormFixed()">
                TENHO INTERESSE
              </button>
            </div>
          </div>
          <div class="d-none d-lg-block col-lg-4">
            <FormCotation :tabMenuActive="tabMenuActive" />
          </div>
        </template>
      </div>
    </div>
  </section>
</template>
<script>
import FormCotation from "@/components/Forms";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    FormCotation,
  },
  computed: {
    ...mapGetters("VehicleNew", ["loading", "detail"]),
    getVehicleColor(){        
        return this.vehicleColor;
    }
  },
  methods: {
      ...mapActions('Site', ['toggleFormFixed']),
      selectTabVersion(index){          
          this.activeTab = 'tab-' + index;
          this.loadColor(index)
      },
      selectVehicleColor(color){
          this.vehicleColor = color;
          this.colorIndex = color.id;
      },
      loadColor(indexVersion){          
          if(this.detail && Object.keys(this.detail).length > 0){
                if(this.detail.versions.length > 0){                                                      
                    this.vehicleColor = this.detail.versions[indexVersion].colors[0];              
                }
            }
      }
  },
  watch: {
      detail(){
          this.loadColor(0);
      }
  },
  mounted(){
  },
  data() {
    return {
      tabMenuActive: "new",      
      activeTab: "tab-0",
      colorIndex: 0,
      vehicleColor: {},
    };
  },
};
</script>