<template>
  <div class="content-vehicle-intern">
    <BannerVehicleIntern />
    <!--  -->
    <SectionVersions />
    <!--  -->
    <SectionFeature />
    <!--  -->
    <SectionGallery />
    <!--  -->
    <SectionDatasheet />
    <!--  -->
    <SectionOtherVehicles />
    <!--  -->
  </div>
</template>

<script>
import BannerVehicleIntern from "./BannerVehicleIntern.vue";
import SectionVersions from "./SectionVersions.vue";
import SectionFeature from "./SectionFeature.vue";
import SectionGallery from "./SectionGallery.vue";
import SectionDatasheet from "./SectionDatasheet.vue";
import SectionOtherVehicles from "./SectionOtherVehicles.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BannerVehicleIntern,
    SectionVersions,
    SectionFeature,
    SectionOtherVehicles,
    SectionGallery,
    SectionDatasheet
  },
  computed: {
    ...mapGetters("VehicleNew", ["loading", "detail"]),
  },
  methods: {
    ...mapActions("VehicleNew", ["loadVehicleDetail"]),
    ...mapActions("Site", ["toggleFormFixed"]),
  },
  mounted() {
    this.loadVehicleDetail(this.$route.params);

    if (Object.keys(this.detail).length == 0 && !this.loading) {
      this.$router.push({name: 'vehicleNews'});
    }
  },
  data() {
    return {
      tabMenuActive: "new",
      activeTab: "tab-1",
    };
  },
};
</script>

<style lang="scss">
.content-description-vehicle {
  position: relative;
  background: #edede3;
  padding: 50px 0px 150px 0px;
  @media (max-width: 991px) {
    padding: 15px 0px;
    padding-top: 0;
  }
  &-itens {
    .content-tab-versions {
      display: flex;
      align-items: center;
      position: absolute;
      top: -54px;
      @media (max-width: 991px) {
        position: relative;
        top: initial;
        flex-wrap: wrap;
        margin-bottom: 30px;
      }
      &-elements {
        border-style: none !important;
        border: 0 !important;
        width: 180px;
        padding: 15px;
        display: flex;
        justify-content: center;
        text-align: center;
        background: rgba(0, 0, 0, 0.6);
        transition: 500ms;
        @media (max-width: 991px) {
          width: 100px;
        }
        &:first-child {
          border-top-left-radius: 3px;
          @media (max-width: 991px) {
            border-top-left-radius: 0px;
          }
        }
        &:last-child {
          border-top-right-radius: 3px;
          @media (max-width: 991px) {
            border-top-right-radius: 0px;
          }
        }
        p {
          font-size: 16px;
          color: var(--white);
          font-family: "Font Medium";
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 0;
          transition: 500ms;
        }
      }
      .active {
        background: #edede3;
        p {
          font-family: "Font Black";
          color: #2f2f32;
        }
      }
    }
    .content-tab-version-div {
      display: none;
    }
    .active-tab {
      display: block;
    }
    &-header {
      margin-bottom: 30px;
      .title {
        position: relative;
        font-size: 32px;
        font-family: "Font Black";
        color: #2f2f32;
        margin-bottom: 0;
        padding-bottom: 6px;
        @media (max-width: 991px) {
          font-size: 20px;
          font-family: "Font Medium";
        }
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 4px;
          width: 128px;
          display: block;
          background: var(--main-color);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          border-radius: 10px;
        }
      }
    }
    &-colors {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      .elements-colors {
        display: flex;
        height: 40px;
        width: 40px;
        border: 1px solid #707070;
        border-radius: 100%;
        margin-right: 5px;
        transition: 500ms;
        @media (max-width: 991px) {
          height: 32px;
          width: 32px;
        }
        &:last-child {
          margin-right: 0;
        }
        &:hover,
        &:focus {
          cursor: pointer;
          opacity: 0.8;
        }
      }
      .active {
        border: 2px solid var(--black);
      }
    }
    &-body {
      .content-text {
        text-align: center;
        margin-bottom: 17px;
        @media (max-width: 991px) {
          margin-top: 27px;
        }
        h3 {
          font-size: 20px;
          font-family: "Font Black";
          color: #2f2f32;
          margin-bottom: 0;
          span {
            display: block;
            font-size: 40px;
          }
        }
      }
      .content-image {
        img {
          max-height: 300px;
        }
      }
      .btn-cotation-default {
        margin: 0 auto;
        @media (max-width: 991px) {
          height: 45px;
          width: 204px;
        }
      }
    }
    .content-form-cotation-header h2 {
      @media (min-width: 992px) and (max-width: 1199px) {
        font-size: 16px;
      }
    }
  }
}
.content-slider-vehicle {
  background: #2f2f32;
  padding: 50px 0px;
  @media (max-width: 991px) {
    background: #edede3;
    padding: 70px 0px 18px 0px;
  }
  .gallery-top {
    .swiper-pagination {
      &-bullets {
        bottom: 0;
      }
      &-bullet {
        height: 18px;
        width: 52px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 10px;
        background: var(--white);
        margin: 0px 5.75px;
        opacity: 1;
        @media (max-width: 991px) {
          height: 5px;
          width: 16px;
          margin: 0px 1.75px;
        }
        &-active {
          background: var(--main-color);
        }
      }
    }
    @media (max-width: 991px) {
      .swiper-button-prev,
      .swiper-button-next {
        svg {
          max-width: 13px;
        }
      }
    }
    .swiper-slide {
      img {
        max-height: 400px;
      }
    }
  }
  .gallery-thumbs {
    padding-top: 20px;
    @media (max-width: 991px) {
      padding-top: 6px;
    }
    .swiper-wrapper {
      margin-left: -45%;
    }
    .swiper-slide {
      cursor: pointer;
      width: 11.1%;
      .gallery-thumbs-image {
        opacity: 0.49;
      }
    }
    .swiper-slide-active {
      .gallery-thumbs-image {
        opacity: 1;
      }
    }
    &-image {
      position: relative;
      display: block;
      height: 70px;
      width: 100%;
      background-position: center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      @media (max-width: 991px) {
        height: 30px;
      }
      // img {
      // 	max-width: 100px;
      // 	max-height: 100px;
      // }
    }
  }
}
.content-vehicle-especification {
  padding: 90px 0px;
  @media (max-width: 991px) {
    padding: 40px 0px;
  }
  &-itens {
    &-header {
      margin-bottom: 80px;
      text-align: left;
      @media (max-width: 991px) {
        margin-bottom: 50px;
        text-align: center;
      }
      .title {
        position: relative;
        font-size: 40px;
        font-family: "Font Bold";
        color: #2f2f32;
        margin-bottom: 0;
        padding-bottom: 6px;
        @media (max-width: 991px) {
          font-size: 20px;
        }
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          height: 4px;
          width: 128px;
          display: block;
          background: var(--main-color);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          border-radius: 10px;
          @media (max-width: 991px) {
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    &-body {
      .tabs {
        .nav-tabs {
          border-style: none;
          border-bottom: 0;
          .nav-item {
            margin-right: 30px;
            margin-bottom: 15px;
            .nav-link {
              border-style: none;
              font-size: 18px;
              font-family: "Font Medium";
              color: #bebebe;
              padding-top: 0;
              padding-left: 0;
              padding-right: 0;
              position: relative;
              transition: 500ms;
              @media (max-width: 576px) {
                font-size: 16px;
              }
              @media (max-width: 991px) {
                font-size: 14px;
              }
              &::after {
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                height: 4px;
                width: 100%;
                background: var(--main-color);
                border: 1px solid var(--white);
                opacity: 0;
                transition: 500ms;
              }
            }
            .active {
              color: var(--main-color);
              &::after {
                content: "";
                opacity: 1;
              }
            }
          }
        }
      }
      .tab-content {
        padding-top: 30px;
        @media (max-width: 991px) {
          padding-top: 15px;
        }
        ul {
          list-style: none;
          padding: 0;
          text-align: left;
          li {
            font-size: 20px;
            font-family: "Font Regular";
            color: #707070;
            margin-bottom: 20px;
            @media (max-width: 991px) {
              font-size: 16px;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}
.content-others-vehicles {
  padding: 50px 0px;
  background: #f3f5f8;
  &-itens {
    &-header {
      margin-bottom: 50px;
      text-align: left;
      @media (max-width: 991px) {
        text-align: center;
      }
      .title {
        position: relative;
        font-size: 40px;
        font-family: "Font Bold";
        color: #2f2f32;
        margin-bottom: 0;
        padding-bottom: 6px;
        @media (max-width: 991px) {
          font-size: 20px;
        }
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          height: 4px;
          width: 128px;
          display: block;
          background: var(--main-color);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          border-radius: 10px;
          @media (max-width: 991px) {
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    &-body {
      .content-btn {
        display: flex;
        justify-content: flex-end;
        @media (max-width: 991px) {
          justify-content: center;
        }
        .btn-view-plus {
          height: 63px;
          width: 155px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--white);
          border-radius: 10px;
          box-shadow: 0px 3px 5px rgba(0, 7, 4, 0.36);
          font-size: 20px;
          font-family: "Font Medium";
          color: var(--main-color);
          transition: 500ms;
          @media (max-width: 991px) {
            font-size: 16px;
            height: 50px;
            width: 130px;
          }
          &:hover,
          &:focus {
            cursor: pointer;
            background: var(--main-color);
            color: var(--white);
          }
        }
      }
    }
  }
}
.content-vehicle-caracteristics {
  position: relative;
  z-index: 2;
  padding-bottom: 50px;
  padding-top: 50px;
  border-bottom: 1px solid rgba(59, 59, 59, 0.8);
  @media (max-width: 991px) {
    padding: 30px 0px;
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(59, 59, 59, 0.9);
    z-index: -1;
  }
  &-header {
    text-align: center;
    margin-bottom: 30px;
    @media (max-width: 991px) {
      margin-bottom: 20px;
    }
    &-title {
      font-size: 26px;
      color: var(--white);
      font-family: "Font Bold";
      margin: 0;
      @media (max-width: 991px) {
        font-size: 20px;
      }
    }
  }
  &-body {
    &-itens {
      display: flex;
      margin-bottom: 30px;
      @media (max-width: 991px) {
        flex-direction: column;
        margin-bottom: 15px;
      }
      &:nth-of-type(even) {
        flex-direction: row-reverse;
        @media (max-width: 991px) {
          flex-direction: column;
        }
      }
      &-text {
        width: 50%;
        background: #707070;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 30px;
        @media (max-width: 991px) {
          width: 100%;
          padding: 20px 15px;
        }
        p {
          &:first-of-type {
            font-size: 26px;
            color: var(--white);
            font-family: "Font Bold";
            margin: 0;
            margin-bottom: 20px;
            @media (max-width: 991px) {
              font-size: 18px;
              margin-bottom: 5px;
            }
          }
          &:last-of-type {
            font-size: 16px;
            color: #dbdbdb;
            font-family: "Font Medium";
            margin: 0;
            @media (max-width: 991px) {
              font-size: 14px;
            }
          }
        }
      }
      &-img {
        width: 50%;
        min-height: 300px;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        @media (max-width: 991px) {
          width: 100%;
          min-height: 200px;
        }
      }
    }
    &-btn {
      display: flex;
      justify-content: center;
      @media (max-width: 991px) {
        flex-direction: column;
      }
      &-item {
        &-1 {
          font-size: 16px;
          color: var(--white);
          font-family: "Font Bold";
          background: var(--main-color);
          border: 1px solid var(--main-color);
          padding: 10px 20px;
          border-radius: 4px;
          transition: 500ms;
          margin-right: 30px;
          &:hover,
          &:focus {
            background: #ab0012;
            border: 1px solid #ab0012;
            cursor: pointer;
          }
          @media (max-width: 991px) {
            font-size: 14px;
            width: 60%;
            margin: 20px auto 15px auto;
            @media (max-width: 320px) {
              font-size: 12px;
            }
          }
        }
        &-2 {
          background: #707070;
          border: 1px solid #707070;
          color: var(--white) !important;
          font-family: "Font Bold";
          font-size: 16px;
          padding: 10px 20px;
          border-radius: 4px;
          transition: 500ms;
          text-align: center;
          &:hover,
          &:focus {
            background: #dbdbdb;
            border: 1px solid #dbdbdb;
            cursor: pointer;
            color: #707070 !important;
          }
          @media (max-width: 991px) {
            font-size: 14px;
            width: 60%;
            margin: 0 auto;
            @media (max-width: 320px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .nav-tabs {
    border: 0;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    .nav-item {
      border: 0 !important;
      border-style: 0;
      &:first-of-type {
        .nav-link {
          border-top-left-radius: 4px !important;
          border-bottom-left-radius: 4px !important;
        }
      }
      &:last-of-type {
        .nav-link {
          border-top-right-radius: 4px !important;
          border-bottom-right-radius: 4px !important;
          border-right: 0 !important;
        }
      }
      .nav-link {
        border: 0 !important;
        border-style: none !important;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-right: 1px solid #d6d6d6 !important;
        padding: 20px;
        transition: 500ms;
        font-size: 16px;
        color: var(--black);
        font-family: "Font Bold";
        background: var(--white);
        @media (min-width: 992px) and (max-width: 1199px){
          font-size: 14px;
        }
        @media (max-width: 991px) {
          padding: 10px;
          font-size: 10px;
        }
        &:hover,
        &:focus {
          color: var(--white) !important;
          background: var(--black) !important;
        }
        &.active {
          color: var(--white) !important;
          background: var(--black) !important;
        }
      }
    }
  }
}
</style>