<template>
    <section class="content-vehicle-caracteristics">
      <div v-if="loading" class="row">
          <div class="col-sm-12">
              <PuSkeleton class="w-100" height="200px" />
          </div>
      </div>
      <div v-else-if="Object.keys(detail).length > 0 && detail.details.length > 0" class="content-vehicle-caracteristics-itens container">
        <div class="content-vehicle-caracteristics-header">
          <p class="content-vehicle-caracteristics-header-title">
            Principais Características
          </p>
        </div>
        <b-tabs>
          <b-tab
            v-for="(features, group, index) in $root.groupBy(detail.details, 'group_name')"
            :key="index"
            :title="group"
            :class="{ active: index === 0 }"
          >
            <template v-for="(feature, indexFeature) in features">
                <div :key="indexFeature" class="content-vehicle-caracteristics-body-itens">
                <div class="content-vehicle-caracteristics-body-itens-text">
                    <p>{{ feature.name }}</p>
                    <p v-html="feature.description"></p>
                </div>
                <div class="content-vehicle-caracteristics-body-itens-img" :style="{'background': `url('${feature.image_path}')`}">
                    <!---<img
                    class="img-fluid w-100"
                    :src="feature.image_path"
                    />
                    -->
                </div>
                </div>
            </template>
            <div class="content-vehicle-caracteristics-body-btn">
              <button
                class="
                  content-vehicle-caracteristics-body-btn-item-1
                  btn-cotation
                "
                @click="toggleFormFixed()"
              >
                {{ $t("vehicle_new_details.button_cotation_2")|stripHtml }}
              </button>
              <router-link
                tag="a"
                :to="{ name: 'News' }"
                class="content-vehicle-caracteristics-body-btn-item-2"
              >
                {{ $t("vehicle_new_details.button_others_models")|stripHtml }}
              </router-link>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters('VehicleNew', ['loading', 'detail'])
    },
    methods: {
        ...mapActions('Site', ['toggleFormFixed'])
    }
}
</script>