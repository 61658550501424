<template>
  <router-link
    tag="a"
    :to="{name: 'vehicleNewDetail', params: {
        slug: vehicle.slug
      }}"
    class="content-card-others-vehicles"
  >
    <div class="content-card-others-vehicles-text">
      <h2 class="name">{{ vehicle.name }}</h2>
      <p class="description">{{vehicle.subtitle}}</p>
    </div>
    <div class="content-card-others-vehicles-image">
      <img
        class="img-fluid"
        :src="vehicle.thumb_path"
        alt="image others vehicles"
        title="image others vehicles"
      />
    </div>
  </router-link>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    vehicle: Object,
  },
  methods: {
    ...mapActions("Site", ["toggleFormFixed"]),
  },
};
</script>

<style lang="scss">
.content-card-others-vehicles {
  display: block;
  padding: 40px;
  background: var(--white);
  border: 1px solid #cbcbcb;
  border-radius: 10px;
  margin-bottom: 30px;
  transition: 500ms;
  @media (max-width: 991px) {
    padding: 20px;
    margin-bottom: 20px;
  }
  &:hover,
  &:focus {
    cursor: pointer;
    border: 1px solid transparent;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }
  &-text {
    text-align: left;
    .name {
      font-size: 26px;
      font-family: "Font Black";
      color: #3e3e3e;
      margin-bottom: 0;
      @media (max-width: 991px) {
        font-size: 20px;
      }
    }
    .description {
      font-size: 14px;
      font-family: "Font Regular";
      color: #3e3e3e;
      height: 40px;
      margin-bottom: 0;
    }
  }
  &-image {
    text-align: center;
  }
}
</style>