<template>
    <section class="content-vehicle-especification">
      <div class="content-vehicle-especification-itens container">
        <div class="content-vehicle-especification-itens-header">
          <h2 class="title">{{ $t("vehicle_new_details.titlte_specifications")|stripHtml }}</h2>
        </div>
        <template v-if="loading">
            <PuSkeleton class="w-100" height="300px" />
        </template>
        <div v-else-if="Object.keys(detail).length > 0 && detail.datasheet.length > 0" class="content-vehicle-especification-itens-body">
          <b-tabs>
            <b-tab v-for="(info, index) in detail.datasheet" :key="index" :title="info.category">
              <div class="content-text" style="text-align: initial;" v-html="info.description"></div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </section>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('VehicleNew', ['loading', 'detail'])
    }
}
</script>