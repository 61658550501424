<template>
    <section class="content-others-vehicles">
      <div class="content-others-vehicles-itens container">
        <div class="content-others-vehicles-itens-header">
          <h2 class="title">{{ $t("vehicle_new_details.title_other_vehicles")|stripHtml }}</h2>
        </div>
        <div class="content-others-vehicles-itens-body">
          <div class="row">
            <template v-if="loading">
                <div class="col-12 col-sm-6 col-lg-4 col-xl-3" v-for="n in 3" :key="n">
                    <PuSkeleton height="200px" />
                </div>                
            </template>
            <template v-else>
                <div v-for="(vehicle, index) in other_vehicles" :key="index" class="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <CardOthersVehicles :vehicle="vehicle" />
                </div>
            </template>
          </div>
          <div class="content-btn">
            <router-link tag="a" :to="{name: 'vehicleNews' }" class="btn-view-plus">
              {{ $t("vehicle_new_details.button_more_vehicles")|stripHtml }}
            </router-link>
          </div>
        </div>
      </div>
    </section>
</template>
<script>
import CardOthersVehicles from "@/components/cards/CardOthersVehicles.vue";
import { mapGetters } from 'vuex';

export default {
    components: {
        CardOthersVehicles
    },
    computed: {
        ...mapGetters('VehicleNew', ['loading', 'detail', 'other_vehicles']),
    },
    mounted() {        
    },
    data(){
        return{            
        }
    }
}
</script>