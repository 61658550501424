<template>
    <div>
        <template v-if="loading">
            <PuSkeleton class="w-100" height="300px" />
        </template>    
        <section v-show="Object.keys(detail).length > 0 && detail.gallery_external_path.length > 0" class="content-slider-vehicle">
            <div class="content-slider-vehicle-itens container">
                <!-- swiper1 -->
                <swiper
                class="swiper gallery-top"
                :options="swiperOptionTop"
                ref="swiperTop"
                >
                <swiper-slide v-for="(image, index) in detail.gallery_external_path" :key="index">
                    <img
                    class="img-fluid"
                    :src="image"
                    alt="image gallery vehicle"
                    title="image gallery vehicle"
                    />
                </swiper-slide>
                <div
                    class="swiper-pagination swiper-pagination-gallery"
                    slot="pagination"
                ></div>
                <div
                    class="swiper-button-prev swiper-button-prev-gallery"
                    slot="button-prev"
                >
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="43.584"
                    height="74.187"
                    viewBox="0 0 43.584 74.187"
                    >
                    <g transform="translate(43.584 74.187) rotate(180)">
                        <g transform="translate(0 0)">
                        <path
                            d="M42.4,34.2,9.387,1.185a4.067,4.067,0,0,0-5.74,0L1.216,3.616a4.064,4.064,0,0,0,0,5.74L28.939,37.078,1.185,64.832a4.068,4.068,0,0,0,0,5.74L3.616,73a4.067,4.067,0,0,0,5.74,0L42.4,39.958a4.1,4.1,0,0,0,0-5.759Z"
                            fill="#c00"
                        />
                        </g>
                    </g>
                    </svg>
                </div>
                <div
                    class="swiper-button-next swiper-button-next-gallery"
                    slot="button-next"
                >
                    <svg
                    style="transform: rotate(180deg)"
                    xmlns="http://www.w3.org/2000/svg"
                    width="43.584"
                    height="74.187"
                    viewBox="0 0 43.584 74.187"
                    >
                    <g transform="translate(43.584 74.187) rotate(180)">
                        <g transform="translate(0 0)">
                        <path
                            d="M42.4,34.2,9.387,1.185a4.067,4.067,0,0,0-5.74,0L1.216,3.616a4.064,4.064,0,0,0,0,5.74L28.939,37.078,1.185,64.832a4.068,4.068,0,0,0,0,5.74L3.616,73a4.067,4.067,0,0,0,5.74,0L42.4,39.958a4.1,4.1,0,0,0,0-5.759Z"
                            fill="#c00"
                        />
                        </g>
                    </g>
                    </svg>
                </div>
                </swiper>
                <!-- swiper2 thumbs -->
                <swiper
                class="swiper gallery-thumbs"
                :options="swiperOptionThumbs"
                ref="swiperThumbs"
                >
                <swiper-slide v-for="(image, index) in detail.gallery_external_path" :key="index">
                    <div
                    class="gallery-thumbs-image"
                    :style="{
                        background: `url('${image}')`,
                    }"
                    >
                    <!-- <img class="img-fluid" src="@/assets/img/vehicle-intern/image-gallery.jpg" alt="image gallery vehicle miniatura" title="image gallery vehicle miniatura"/> -->
                    </div>
                </swiper-slide>
                </swiper>
            </div>
        </section>
    </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { mapGetters } from 'vuex';

export default {
    data(){
        return{
            swiperOptionTop: {
                notNextTick: true,
                spaceBetween: 10,
                speed: 800,
                // pagination: {
                // el: ".swiper-pagination-gallery",
                // type: "bullets",
                // clickable: true,
                // },
                navigation: {
                nextEl: ".swiper-button-next-gallery",
                prevEl: ".swiper-button-prev-gallery",
                },
            },
            swiperOptionThumbs: {
                notNextTick: true,
                spaceBetween: 4.6,
                centeredSlides: true,
                slidesPerView: "auto",
                touchRatio: 0.2,
                slideToClickedSlide: true,
                speed: 800,
            },
        }
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    computed: {
        ...mapGetters('VehicleNew', ['loading', 'detail']),
    },
    mounted() {        
        const swiperTop = this.$refs.swiperTop.$swiper;
        const swiperThumbs = this.$refs.swiperThumbs.$swiper;
        swiperTop.controller.control = swiperThumbs;
        swiperThumbs.controller.control = swiperTop;
    },
}
</script>